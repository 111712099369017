import React, { ReactElement } from "react";
import { Link } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";

interface PrimaryLinkProps {
  width?: string;
  text: string;
  path: string;
  outlined?: boolean;
  Icon?: React.ComponentType<{ size: number; className: any }>;
}

const PrimaryLink: React.FC<PrimaryLinkProps> = ({
  width,
  text,
  path,
  outlined,
  Icon,
}: PrimaryLinkProps): ReactElement => {
  const { slug } = useAuthContext();
  return (
    <div>
      <Link
        className={`flex items-center justify-center ${
          outlined ? "text-primary  bg-white" : "text-white  bg-primary"
        } ${
          width ? width : "w-full"
        } my-5 mx-4 py-3 px-5 rounded-sm border border-primary text-center text-xs font-medium`}
        to={`/${slug}${path}`}
      >
        {Icon && (
          <Icon
            size={20}
            className={`${outlined ? "text-primary  " : "text-white "} mr-2`}
          />
        )}
        {text}
      </Link>
    </div>
  );
};

export default PrimaryLink;
