import React, { useState } from "react";
import { BackLink } from "../../components/links";
import { Button, InputField, SelectField } from "../../components/forms";
import { useForm } from "react-hook-form";
import { textReg } from "../../utils/regex";
import { LuSaveAll } from "react-icons/lu";
import { onCreateFormStructure } from "../../utils/services/api/dynamicFom";
import { toast } from "react-toastify";
import { useAuthContext } from "../../context/AuthContext";
import { Spinner } from "../../components/generic";
import { useNavigate } from "react-router-dom";
import useIdentityHook from "../../hooks/useIdentityHook";

const CreateFormStructure = () => {
  const [loading, setLoading] = useState(false);
  const { control, handleSubmit } = useForm();
  const { authUser, config, slug } = useAuthContext();
  const { identities } = useIdentityHook();
  const navigate = useNavigate();

  const tempIdentity: { value: number; label: string }[] = identities.map(
    ({ id, Title }) => ({
      value: id,
      label: Title,
    })
  );
  const onSave = async (d: any) => {
    setLoading(true);
    let formData = {
      AuthorID: authUser.UserID,
      IDN_ID: d.IDN_ID,
      Title: d.Title,
      Description: d.Description,
      Instruction: d.Instruction,
    };
    try {
      setLoading(true);
      await onCreateFormStructure(formData, config);
      setLoading(false);
      navigate(`/${slug}/forms`);
      toast.success("Form Created Successfully");
    } catch (error: any) {
      setLoading(false);
      toast.error(error);
    }
    setLoading(false);
  };
  const onContinue = async (d: any) => {
    setLoading(true);
    let formData = {
      AuthorID: authUser.UserID,
      IDN_ID: "2",
      Title: d.Title,
      Description: d.Description,
      Instruction: d.Instruction,
    };
    try {
      setLoading(true);
      let res = await onCreateFormStructure(formData, config);
      navigate(`/${slug}/forms/add-fields/${res?.data?.id?.toString()}`);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      toast.error(error);
    }
    setLoading(false);
  };
  return (
    <div>
      {/* Spinner */}
      <Spinner loading={loading} />
      {/* Header */}
      <div className="flex justify-between items-center">
        <BackLink title="New Form" />
        <Button
          onBtnClick={handleSubmit(onSave)}
          text="Save"
          width={140}
          Icon={LuSaveAll}
        />
      </div>
      {/* Body */}
      <div className="w-full bg-white py-8 px-20 drop-shadow-xs rounded-sm">
        <InputField
          name="Title"
          title="Form Title"
          placeholder="Enter Form Title"
          control={control}
          rules={{
            required: "Form Title is required",
            pattern: {
              value: textReg,
              message: "Only Text are allowed",
            },
          }}
          showCount
          maxLength={30}
        />
        {/* Select Items */}
        <SelectField
          control={control}
          name="IDN_ID"
          // rules={{ required: "Target Audience is required" }}
          placeholder="Select Target Audience"
          title="Target Audience"
          options={tempIdentity}
          isValue
        />
        <InputField
          name="Description"
          title="Form Description"
          placeholder="Enter Form Description"
          control={control}
          rules={{
            required: "Form Description is required",
          }}
          showCount
          maxLength={500}
          isTextArea
        />
        <InputField
          name="Instruction"
          title="Form Instruction"
          placeholder="Enter Form Instruction"
          control={control}
          rules={{
            required: "Form Instruction is required",
          }}
          showCount
          maxLength={500}
          isTextArea
        />
        <div className="w-full flex items-center justify-end">
          <Button
            onBtnClick={handleSubmit(onContinue)}
            text="Continue"
            outlined
            width={180}
          />
        </div>
      </div>
    </div>
  );
};

export default CreateFormStructure;
