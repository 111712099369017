import React, { useState } from "react";
import { BackLink } from "../../components/links";
import { Button, InputField } from "../../components/forms";
import { useForm } from "react-hook-form";
import { FaSearch } from "react-icons/fa";
import { TableData } from "../../components/layouts/Table";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";
import { Spinner } from "../../components/generic";
import useIdentityHook from "../../hooks/useIdentityHook";
import { toast } from "react-toastify";
import {
  OnUpdateFormStatus,
  onCreateFormPermission,
} from "../../utils/services/api/dynamicFom";
const PublishForm = () => {
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [active, setActive] = useState("Mobile Users");
  const [isLoading, setIsLoading] = useState(false);
  const tabItems = ["Mobile Users"];
  const { id } = useParams();
  const { control } = useForm();
  const navigate = useNavigate();
  const { slug, config, authUser } = useAuthContext();

  const { mobileUsers, loading } = useIdentityHook();
  const tbHeader = [
    "FAID",
    "Name",
    "Email",
    "Phone Number",
    "State",
    "Role",
    "status",
  ];
  // Function to handle select all checkbox change
  const handleSelectAllChange = () => {
    if (selectAll) {
      setSelectedItems([]);
    } else {
      setSelectedItems(mobileUsers.map((item: any) => item.UniqueID));
    }
    setSelectAll((prev) => !prev);
  };
  // Function to handle checkbox change
  const handleCheckboxChange = (itemId: number) => {
    if (selectedItems.includes(itemId)) {
      setSelectedItems((prevItems) =>
        prevItems.filter((item) => item !== itemId)
      );
    } else {
      setSelectedItems((prevItems) => [...prevItems, itemId]);
    }
  };
  const onPublish = async () => {
    if (selectedItems.length === 0) {
      toast.error("At least one user must be selected");
    } else {
      setIsLoading(true);
      let allRequestsSuccessful = true; // Flag to track individual request success
      const individualPromises = selectedItems.map(async (itemId) => {
        try {
          const formData = {
            UserUniqueID: itemId?.toString(),
            FormID: id?.toString(),
          };
          // Assuming you have a function to make the API call
          await onCreateFormPermission(formData as any, config);
        } catch (error: any) {
          allRequestsSuccessful = false; // Set flag to false if any request fails
          toast.error(`${error}`);
        } finally {
          setIsLoading(false);
        }
      });

      // Wait for all individual requests to complete
      await Promise.all(individualPromises);

      if (allRequestsSuccessful) {
        // Update Form only if all individual requests are successful
        await UpdateForm();
      }
      // else {
      //   toast.error("Some individual requests failed. Form not updated.");
      // }
    }
  };

  const UpdateForm = async () => {
    try {
      setIsLoading(true);
      let updateData = {
        AuthorID: authUser?.UserID,
        Status: "published",
      };
      await OnUpdateFormStatus(updateData, id as string, config);
      toast.success("Form Publish successfully");
      navigate(`/${slug}/forms`); // Redirect to home after success
    } catch (error: any) {
      toast.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div>
      {/* SPinner */}
      <Spinner loading={loading} />
      <Spinner loading={isLoading} />
      {/* Header */}
      <div className="w-full flex justify-between items-center">
        <BackLink title={"Publish Form"} />{" "}
        <div className="flex items-center">
          {/* Search */}
          <div className="flex items-center">
            <InputField
              name="searchParams"
              placeholder="Search by Name or ID"
              control={control}
              rules={{
                required: "Search Field can not be empty",
              }}
            />
            <button className="bg-primary h-10 w-10 flex justify-center items-center rounded-sm ml-2">
              <FaSearch className="text-white" />
            </button>
          </div>
        </div>
      </div>
      {/* Tab and Search Container */}
      <div className="w-full flex items-center justify-between bg-white px-3 mb-5">
        {/* Tabs */}
        <ul className="flex items-center justify-start">
          {tabItems.map((item, index) => (
            <li
              className={`text-sm font-normal px-4 py-3 cursor-pointer ${
                item === active ? "bg-primary text-white" : ""
              }`}
              key={index}
              onClick={() => setActive(item)}
            >
              {item}
            </li>
          ))}
        </ul>
      </div>
      {/* Table Header */}
      <table className="w-full bg-white">
        <thead className="w-full bg-[#E0DFE5]">
          {/* <tr className="w-full">
            {tbHeader.map((item, index) => (
              <th
                key={index}
                scope="col"
                className="px-6 py-3 uppercase text-left text-xs font-medium whitespace-nowrap"
              >
                {item}
              </th>
            ))}
          </tr> */}
        </thead>
        {/* Table content */}
        <tbody className="w-full bg-white">
          {/* Table Header */}
          <table className="w-full bg-white">
            <thead className="w-full bg-[#E0DFE5]">
              <tr className="w-full">
                <th
                  scope="col"
                  className="px-6 py-3 uppercase text-left text-xs font-medium whitespace-nowrap"
                >
                  <input
                    type="checkbox"
                    checked={selectAll}
                    onChange={handleSelectAllChange}
                  />
                </th>
                {tbHeader.map((item, index) => (
                  <th
                    key={index}
                    scope="col"
                    className="px-6 py-3 uppercase text-left text-xs font-semibold whitespace-nowrap"
                  >
                    {item}
                  </th>
                ))}
              </tr>
            </thead>
            {/* Table content */}
            <tbody className="w-full bg-white">
              {mobileUsers.map((item: any, index) => (
                <tr
                  className="bg-white border-b pt-8  hover:bg-gray-50"
                  key={index}
                >
                  <td className="px-6 py-3 uppercase text-left text-xs font-medium whitespace-nowrap">
                    <input
                      type="checkbox"
                      checked={selectedItems.includes(item.UniqueID)}
                      onChange={() => handleCheckboxChange(item.UniqueID)}
                    />
                  </td>
                  <TableData item={item?.id} />
                  <TableData item={`${item?.FirstName} ${item?.LastName}`} />
                  <TableData item={item?.Email} />
                  <TableData item={item?.Phone} />
                  <TableData item={item?.State} />
                  <TableData item={item?.Role} />
                  <td className="">
                    <div className="flex items-center justify-center py-3">
                      <div className="w-[6px] h-[6px] bg-primary rounded-full mr-2"></div>
                      <p className="text-xs">Active</p>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </tbody>
        {/* BUtton */}
        <div className="w-full flex justify-end items-end">
          <Button text="Publish" width={120} onBtnClick={onPublish} />
        </div>
      </table>
    </div>
  );
};

export default PublishForm;
