import React, { useState } from "react";
import { BackLink, PrimaryLink } from "../../../components/links";
import { Button, InputField, SelectField } from "../../../components/forms";
import { useForm } from "react-hook-form";
import { emailReg, numReg, textReg } from "../../../utils/regex";
import { toast } from "react-toastify";
import { useAuthContext } from "../../../context/AuthContext";
import { Spinner } from "../../../components/generic";
import { Icon } from "@iconify/react";
import { states } from "../../../utils/data/states";
import { onCreateMobileUser } from "../../../utils/services/api/identities";

const AddMobileUser = () => {
  const [loading, setLoading] = useState(false);
  const [onShowModal, setShowModal] = useState(false);
  const { control, handleSubmit } = useForm();
  const { authUser, config } = useAuthContext();
  const onSubmit = async (d: any) => {
    setLoading(true);
    try {
      setLoading(true);
      await onCreateMobileUser(d, authUser?.OrganizationID, config);
      setLoading(false);
      setShowModal(true);
    } catch (error: any) {
      setLoading(false);
      toast.error(error);
    }
    setLoading(false);
  };
  return (
    <div>
      {/* Spinner */}
      <Spinner loading={loading} />
      {/* Header */}
      <div className="flex justify-between items-center">
        <BackLink title="New User" />
      </div>
      {/* Body */}
      <div className="w-full h-[100vh] flex items-center justify-center bg-white py-8 px-20 drop-shadow-xs rounded-sm">
        {/* Form Content */}
        <div className="w-[60%]">
          <div className="flex justify-between items-center">
            <div className="w-[45%]">
              <InputField
                name="FirstName"
                title="First Name"
                placeholder="Enter First Name"
                control={control}
                rules={{
                  required: "First Name is required",
                  pattern: {
                    value: textReg,
                    message: "Only Text are allowed",
                  },
                }}
              />
            </div>
            <div className="w-[45%]">
              <InputField
                name="LastName"
                title="Last Name"
                placeholder="Enter Last Name"
                control={control}
                rules={{
                  required: "Last Name is required",
                  pattern: {
                    value: textReg,
                    message: "Only Text are allowed",
                  },
                }}
              />
            </div>
          </div>
          <div className="flex justify-between items-center">
            <div className="w-[45%]">
              <InputField
                name="Phone"
                title="Phone Number"
                placeholder="Enter Phone Number"
                control={control}
                rules={{
                  required: "Phone Number is required",
                  pattern: {
                    value: numReg,
                    message: "Only Number are allowed",
                  },
                }}
              />
            </div>
            <div className="w-[45%]">
              <InputField
                name="Email"
                title="Email Address"
                placeholder="Enter Email Address"
                control={control}
                rules={{
                  required: "Email Address is required",
                  pattern: {
                    value: emailReg,
                    message: "Only Text are allowed",
                  },
                }}
              />
            </div>
          </div>
          <div className="flex justify-between items-center">
            <div className="w-[45%]">
              <SelectField
                control={control}
                name="Gender"
                rules={{ required: "Gender is required" }}
                placeholder="Select Gender"
                title="Gender"
                options={["Male", "Female"]}
              />
            </div>
            <div className="w-[45%]">
              <SelectField
                control={control}
                name="State"
                rules={{ required: "State is required" }}
                placeholder="Select State"
                title="State"
                options={states}
              />
            </div>
          </div>
          <div className="flex justify-between items-center">
            <div className="w-full">
              <SelectField
                control={control}
                name="Role"
                rules={{ required: "Role is required" }}
                placeholder="Choose Select"
                title="Role"
                options={["Field Agent"]}
              />
            </div>
          </div>
          {/* Buttons */}
          <div className="w-full flex items-center justify-end">
            <div className="w-[120px] mr-10">
              <PrimaryLink path="/mobile-users" outlined text="Cancel" />
            </div>
            <Button
              onBtnClick={handleSubmit(onSubmit)}
              text="Create"
              width={120}
            />
          </div>
        </div>
      </div>
      {onShowModal && <Modal />}
    </div>
  );
};

export default AddMobileUser;
const Modal = () => {
  return (
    <>
      <div
        id="popup-modal"
        tabIndex={-1}
        className="absolute top-0 left-0 w-full h-full overflow-y-hidden z-50 flex justify-between bg-[rgba(0,0,0,0.3)]"
      >
        <div className="w-[20%]"></div>
        <div className="w-[80%] flex justify-center items-center">
          <div className="ml-0 p-4 w-[40%] bg-white rounded-sm py-8">
            <div className="w-full flex justify-center items-center">
              <Icon
                icon={"clarity:success-standard-line"}
                height={80}
                width={80}
                className="text-primary text-center"
              />
            </div>
            <h1 className="text-lg font-medium text-center my-5">
              You have Successfully added new user
            </h1>
            <div className="flex justify-center items-center">
              <PrimaryLink text="Done" path="/mobile-users" width={"120"} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
