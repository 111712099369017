import React from "react";
import { Controller } from "react-hook-form";

interface InputFieldProps {
  control: any;
  name: string;
  rules?: object;
  placeholder: string;
  secureTextEntry?: boolean;
  title?: string;
  type?: any;
  numberOfLines?: number;
  maxLength?: number;
  showCount?: boolean;
  isTextArea?: boolean;
}

const InputField: React.FC<InputFieldProps> = ({
  control,
  name,
  rules = {},
  placeholder,
  title,
  type,
  maxLength,
  showCount,
  isTextArea,
}) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <div className="my-2">
          {title && (
            <div className="flex items-center justify-between">
              <p className="text-xs mb-2">{title}</p>
              <div>
                {showCount && (
                  <p className="text-xs">{`${
                    value?.length > 0 ? value?.length : 0
                  }${maxLength ? "/" + maxLength : ""}`}</p>
                )}
              </div>
            </div>
          )}
          {isTextArea ? (
            <textarea
              value={value}
              placeholder={placeholder}
              onChange={(text) => onChange(text)}
              className={`block bg-gray-50 text-xs py-3 px-4 rounded-sm w-full shadow-xs border ${
                error ? "border-red-500" : "border-gray-200"
              }`}
              maxLength={maxLength || undefined}
              rows={3}
            ></textarea>
          ) : (
            <input
              type={type || "text"}
              value={value}
              placeholder={placeholder}
              onChange={(text) => onChange(text)}
              className={`block bg-gray-50 text-xs py-3 px-4 rounded-sm w-full shadow-xs border ${
                error ? "border-red-500" : "border-gray-200"
              }`}
              maxLength={maxLength || undefined}
            />
          )}
          {error && (
            <span className="text-red-500 text-xs self-stretch">
              {error.message || "Error"}
            </span>
          )}
        </div>
      )}
    />
  );
};

export default InputField;
