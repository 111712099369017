import { Icon } from "@iconify/react";
import React, { FC, useState } from "react";
import { RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";
interface Props {
  link: any;
}
const DropDown: FC<Props> = ({ link }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { activePage, setActivePage } = useAuthContext();
  const linkClass =
    "flex items-center justify-between w-full transition-colors duration-300  px-3 py-2 hover:no-underline rounded-md text-base";
  return (
    <div>
      <button onClick={() => setIsOpen((prev) => !prev)} className={linkClass}>
        <span className={"flex items-center"}>
          <Icon icon={link.icon} height={20} width={20} />
          <span className="pl-2 text-[14px]">{link.label}</span>
        </span>
        {isOpen ? (
          <RiArrowUpSLine fontSize={20} />
        ) : (
          <RiArrowDownSLine fontSize={20} />
        )}
      </button>
      {isOpen && (
        <div className="w-full">
          {link.dropdown.map((link: any) => {
            return (
              <Link
                key={link.key}
                to={link.path}
                className={`${
                  link.key === activePage
                    ? "bg-primary text-white hover:text-white"
                    : "text-white  hover:text-primary"
                } flex items-center w-full transition-colors duration-300  px-3 py-3 hover:no-underline rounded-md`}
                onClick={() => setActivePage(link.key)}
              >
                {/* <Icon icon={link.icon} height={20} width={20} /> */}
                <span className="pl-2 text-sm">{link.label}</span>
              </Link>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default DropDown;
