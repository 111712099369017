import React, { useState } from "react";
import { BackLink } from "../../../components/links";
import { Button, InputField } from "../../../components/forms";
import { useForm } from "react-hook-form";
import { textReg } from "../../../utils/regex";
import { LuSaveAll } from "react-icons/lu";
import { toast } from "react-toastify";
import { useAuthContext } from "../../../context/AuthContext";
import { Spinner } from "../../../components/generic";
import { useNavigate } from "react-router-dom";
import { onCreateAudience } from "../../../utils/services/api/identities";

const IdentityStructure = () => {
  const [loading, setLoading] = useState(false);
  const { control, handleSubmit } = useForm();
  const { config, slug } = useAuthContext();
  const navigate = useNavigate();

  const OnSave = async (d: any) => {
    setLoading(true);
    let formData = {
      Title: d.Title,
      Type: "Target Audience",
      Description: d.Description,
      Instruction: d.Instruction,
    };
    try {
      setLoading(true);
      await onCreateAudience(formData, config);
      navigate(`/${slug}/target-audience`);
      toast.success("Target Audience Structure Created Successfully");
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      toast.error(error);
    }
    setLoading(false);
  };
  const OnContinue = async (d: any) => {
    setLoading(true);
    let formData = {
      Title: d.Title,
      Type: "Target Audience",
      Description: d.Description,
      Instruction: d.Instruction,
    };
    try {
      setLoading(true);
      let res = await onCreateAudience(formData, config);
      navigate(`/${slug}/audience/add-fields/${res?.data?.id?.toString()}`);
      toast.success("Target Audience Structure Created Successfully");
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      toast.error(error);
    }
    setLoading(false);
  };
  return (
    <div>
      {/* Spinner */}
      <Spinner loading={loading} />
      {/* Header */}
      <div className="flex justify-between items-center">
        <BackLink title="New Audience" />
        <Button
          onBtnClick={handleSubmit(OnSave)}
          text="Save"
          width={140}
          Icon={LuSaveAll}
        />
      </div>
      {/* Body */}
      <div className="w-full bg-white py-8 px-20 drop-shadow-xs rounded-sm">
        <InputField
          name="Title"
          title="Title"
          placeholder="Enter Target Audience Title"
          control={control}
          rules={{
            required: "Target Audience Title is required",
            pattern: {
              value: textReg,
              message: "Only Text are allowed",
            },
          }}
          showCount
          maxLength={30}
        />
        <InputField
          name="Description"
          title="Description"
          placeholder="Enter Target Audience Description"
          control={control}
          rules={{
            required: "Target Audience Description is required",
          }}
          showCount
          maxLength={500}
          isTextArea
        />

        <InputField
          name="Instruction"
          title="Target Audience Instruction"
          placeholder="Enter Target Audience Instruction (Optional)"
          control={control}
          showCount
          maxLength={500}
          isTextArea
        />
        <div className="w-full flex items-center justify-end">
          <Button
            onBtnClick={handleSubmit(OnContinue)}
            text="Continue"
            outlined
            width={180}
          />
        </div>
      </div>
    </div>
  );
};

export default IdentityStructure;
