import React from "react";
import SideBarSingleLink from "./SideBarSingleLink";
import { useParams } from "react-router-dom";
import DropDown from "./DropDown";

const SideBar = () => {
  const { slug } = useParams();
  const DASHBOARD_SIDEBAR_LINKS = [
    {
      key: "dashboard",
      label: "Home",
      path: `/${slug}`,
      icon: "basil:home-solid",
      dropdown: false,
    },
    {
      key: "analytics",
      label: "Analytics",
      path: `/${slug}/analytics`,
      icon: "mdi:analytics",
      dropdown: false,
    },
    {
      key: "users",
      label: "Users",
      path: "#",
      icon: "solar:users-group-rounded-bold",
      dropdown: [
        {
          key: "system-users",
          label: "System Users",
          path: `/${slug}/system-users`,
        },
        {
          key: "mobile-users",
          label: "Mobile Users",
          path: `/${slug}/mobile-users`,
        },
        {
          key: "target-audience",
          label: "Target Audience",
          path: `/${slug}/target-audience`,
        },
      ],
    },
    {
      key: "forms",
      label: "Forms",
      path: `/${slug}/forms`,
      icon: "solar:document-bold",
      dropdown: false,
    },
    {
      key: "records",
      label: "Records",
      path: `/${slug}/records`,
      icon: "ant-design:database-filled",
      dropdown: false,
    },
    {
      key: "settings",
      label: "Settings",
      path: "#",
      icon: "basil:settings-solid",
      dropdown: [
        {
          key: "user-management",
          label: "User Management",
          path: `/${slug}/user-management`,
        },
        {
          key: "roles-permissions",
          label: "Roles and Permission",
          path: `/${slug}/roles-permissions`,
        },
      ],
    },
  ];
  return (
    <div className="bg-[#2E2E2E] text-white w-80  flex-col border-r border-neutral-300 hidden lg:flex md:flex ">
      <div className="pb-8 flex flex-1 flex-col gap-0.5 overflow-y-auto">
        <div className="p-4">
          {DASHBOARD_SIDEBAR_LINKS.map((link, index) => (
            <div className="py-1" key={index}>
              {link.dropdown === false ? (
                <SideBarSingleLink key={index} link={link} />
              ) : (
                <DropDown key={index} link={link} />
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SideBar;
