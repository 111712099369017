import React from "react";
import { useParams } from "react-router-dom";
import { useAuthContext } from "../context/AuthContext";

const Dashboard = () => {
  const { slug } = useParams();
  return <div>{slug} Dashboard</div>;
};

export default Dashboard;
