import React, { useEffect, useState } from "react";
import { BackLink } from "../../../components/links";
import { Button } from "../../../components/forms";
import { FormFieldData } from "../../../utils/predefinedData";
import { FaPlusCircle, FaSave } from "react-icons/fa";
import { FaCirclePlus } from "react-icons/fa6";
import { EndpointFormDTO } from "../../../utils/typings/forms";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthContext } from "../../../context/AuthContext";
import { toast } from "react-toastify";
import { Spinner } from "../../../components/generic";
import { Icon } from "@iconify/react";
import { generateUniqueID } from "../../../utils/functions/generic";
import {
  onCreateIdentityAttributes,
  onGetIdentityDetails,
} from "../../../utils/services/api/identities";

interface Question {
  id: any;
  text: string;
  type: any;
  required: boolean;
  options?: string[];
}

const IdentityAttributes = () => {
  const uniqueID = generateUniqueID();
  const [form, setForm] = useState<EndpointFormDTO>();
  const [loading, setLoading] = useState(false);
  const [questions, setQuestions] = useState<Question[]>([
    {
      id: uniqueID,
      text: "",
      type: "Text",
      required: false,
    },
  ]);
  const [currentOption, setCurrentOption] = useState("");
  const [editingOptionIndex, setEditingOptionIndex] = useState<number | null>(
    null
  );

  const { id } = useParams();
  const { config, slug } = useAuthContext();
  const navigate = useNavigate();

  const delay = (ms: any) => new Promise((resolve) => setTimeout(resolve, ms));

  const getForm = async () => {
    setLoading(true);
    try {
      setLoading(true);
      let res = await onGetIdentityDetails(id, config);
      setLoading(false);
      setForm(res?.data);
    } catch (error: any) {
      setLoading(false);
      toast.error(error);
    }
    setLoading(false);
  };
  useEffect(() => {
    getForm();
    // eslint-disable-next-line
  }, []);
  const addQuestion = () => {
    const newQuestion: Question = {
      id: uniqueID,
      text: "",
      type: "Text",
      required: false,
    };
    setQuestions([...questions, newQuestion]);
  };

  const deleteQuestion = (questionId: any) => {
    setQuestions((prevQuestions) =>
      prevQuestions.filter((q) => q.id !== questionId)
    );
  };
  const addOption = (questionId: any) => {
    if (currentOption.trim() !== "") {
      setQuestions((prevQuestions) =>
        prevQuestions.map((q) =>
          q.id === questionId &&
          ["Drop Down", "Single Select", "Multiple Choice", "Radio"].includes(
            q.type
          )
            ? {
                ...q,
                options: q.options
                  ? [...q.options, currentOption.trim()]
                  : [currentOption.trim()],
              }
            : q
        )
      );
      setCurrentOption(""); // Clear the input field after adding an option
    }
  };
  const deleteOption = (questionId: any, optionIndex: number) => {
    setQuestions((prevQuestions) =>
      prevQuestions.map((q) =>
        q.id === questionId && q.options
          ? { ...q, options: q.options.filter((_, i) => i !== optionIndex) }
          : q
      )
    );
  };
  const updateQuestionType = (questionId: any, newType: any) => {
    setQuestions((prevQuestions) =>
      prevQuestions.map((q) =>
        q.id === questionId ? { ...q, type: newType } : q
      )
    );
  };
  const onSubmit = async () => {
    // Validate options for specific question types
    const invalidOptions = questions.filter(
      (q) =>
        ["Drop Down", "Single Select", "Multiple Choice", "Radio"].includes(
          q.type
        ) &&
        (!q.options ||
          q.options.length < 2 ||
          q.options.some((opt) => opt === ""))
    );

    // Validate questions are not empty
    if (questions.some((q) => q.text.trim() === "")) {
      toast.error("All questions must have a title.");
    } else if (invalidOptions.length > 0) {
      toast.error(
        "Questions with types Drop Down, Single Select, Multiple Choice, or Radio must have at least two options, and options cannot be empty."
      );
    } else {
      try {
        setLoading(true);
        for (const question of questions) {
          const fieldData = {
            IDN_ID: id?.toString(),
            Label: question.text,
            Type: question.type,
            Section: "1",
            Options: question.options ? JSON.stringify(question.options) : "",
            Validations: JSON.stringify([{ required: question.required }]),
          };
          console.log(fieldData);
          // Make API call for the current question
          await onCreateIdentityAttributes(fieldData as any, config);
          // Add a delay if needed (to simulate sequential submission)
          await delay(1000); // Adjust the delay duration as needed
        }

        setLoading(false);
        toast.success(`Columns Added to ${form?.Title} Identity`);
        navigate(`/${slug}/target-audience`);
      } catch (error: any) {
        setLoading(false);
        toast.error(error);
      }
      setLoading(false);
    }
  };
  return (
    <div className=" pb-16">
      <Spinner loading={loading} />
      {/* Header */}
      <div className="flex justify-between items-center">
        <BackLink title={form?.Title} />
        <Button onBtnClick={onSubmit} text="Save" Icon={FaSave} width={120} />
      </div>
      {/* Content */}
      <div className="h-[100vh] flex justify-center bg-white overflow-y-scroll ">
        {/* Main Content */}
        <div className="w-[65%] h-[500px] bg-white px-4 pt-4 rounded-sm pb-10">
          <div className="w-full bg-white pt-6 px-2 ">
            {/* Question and Question Type */}

            {questions.map((question, questionIndex) => (
              <div
                key={question.id}
                className="mb-6 bg-white border border-gray-200 drop-shadow-sm py-3"
              >
                {/* Question and Input Type */}
                <div className="w-full flex justify-between items-center my-2 px-3 mb-4">
                  {/* Question Title */}
                  <div className="w-[65%]">
                    <p className="text-xs mb-2">{`Column Title`}</p>
                    <input
                      type="text"
                      placeholder="Enter Question"
                      value={question.text}
                      onChange={(e) =>
                        setQuestions((prevQuestions) =>
                          prevQuestions.map((q) =>
                            q.id === question.id
                              ? { ...q, text: e.target.value }
                              : q
                          )
                        )
                      }
                      className={`block bg-gray-50 text-xs py-3 px-4 rounded-sm w-full shadow-xs border`}
                    />
                  </div>
                  {/* Input Type */}
                  <div className="w-[30%]">
                    <div className="flex items-center justify-between">
                      <p className="text-xs mb-2">{"Input Type"}</p>
                    </div>
                    <select
                      value={question.type}
                      onChange={(e) =>
                        updateQuestionType(question.id, e.target.value as any)
                      }
                      className={`block bg-gray-50 text-xs py-3 px-4 rounded-sm w-full shadow-xs border "border-gray-200"`}
                    >
                      {FormFieldData.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                {/* Option */}
                {question.options &&
                  question.options.map((option, index) => (
                    <div
                      key={index}
                      className="w-full flex items-center justify-between mb-2 px-6"
                    >
                      <div className="w-[80%] flex items-center">
                        {editingOptionIndex === index ? (
                          <input
                            type="text"
                            value={currentOption}
                            className="w-full border rounded px-3 py-3 mr-2 text-xs"
                            placeholder="Enter Option"
                            onChange={(e) => setCurrentOption(e.target.value)}
                          />
                        ) : (
                          <div className="flex items-center">
                            <Icon
                              icon={"mdi:checkbox-blank-outline"}
                              color="gray"
                              height={40}
                              width={40}
                            />
                            <span className="w-full px-3 py-2 mr-2 text-xs">
                              {option}
                            </span>
                          </div>
                        )}
                      </div>
                      <div className="w-[20%] flex items-center justify-end">
                        <button
                          onClick={() => {
                            if (editingOptionIndex === index) {
                              // Save the edited option
                              setQuestions((prevQuestions) =>
                                prevQuestions.map((q) =>
                                  q.id === question.id && q.options
                                    ? {
                                        ...q,
                                        options: q.options.map((o, i) =>
                                          i === index ? currentOption.trim() : o
                                        ),
                                      }
                                    : q
                                )
                              );
                              setEditingOptionIndex(null);
                              setCurrentOption("");
                            } else {
                              // Start editing the option
                              setCurrentOption(option);
                              setEditingOptionIndex(index);
                            }
                          }}
                          className="ml-2 p-1"
                        >
                          {editingOptionIndex === index ? (
                            <Icon
                              icon={"material-symbols:save-outline"}
                              className="text-primary"
                              height={20}
                              width={20}
                            />
                          ) : (
                            <Icon
                              height={20}
                              width={20}
                              icon={"tabler:edit"}
                              className="text-primary"
                            />
                          )}
                        </button>
                        <button
                          onClick={() => {
                            addOption(question.id);
                            setCurrentOption(option);
                          }}
                          className="ml-2 p-1"
                        >
                          <Icon
                            icon={"uil:copy"}
                            height={20}
                            width={20}
                            className="text-primary"
                          />
                        </button>
                        <button
                          onClick={() => deleteOption(question.id, index)}
                          className="ml-2 p-1"
                        >
                          <Icon
                            icon={"uil:times"}
                            height={30}
                            width={30}
                            className="text-primary"
                          />
                        </button>
                      </div>
                    </div>
                  ))}
                {[
                  "Drop Down",
                  "Single Select",
                  "Multiple Choice",
                  "Radio",
                ].includes(question.type) && (
                  <div className="w-full px-3">
                    <div className="w-full flex justify-between items-center">
                      <input
                        type="text"
                        value={currentOption}
                        onChange={(e) => setCurrentOption(e.target.value)}
                        className="w-full border rounded px-3 py-4 mr-2 text-xs"
                        placeholder="Enter Option"
                      />
                      <Button
                        text="Add Option"
                        onBtnClick={() => addOption(question.id)}
                        width={180}
                        outlined
                        Icon={FaCirclePlus}
                      />
                    </div>
                  </div>
                )}
                {/* Footer */}
                <div className="flex items-center justify-end mt-4 border-t border-t-gray-300 pt-3 px-3">
                  {/* Delete */}
                  <button
                    onClick={() => deleteQuestion(question.id)}
                    disabled={questions.length <= 1 ? true : false}
                    className=""
                  >
                    <Icon
                      icon={"material-symbols:delete-outline"}
                      color="red"
                      height={20}
                      width={20}
                    />
                  </button>
                </div>
              </div>
            ))}
            {/* Validation and Add Button */}
            <div className="flex justify-end items-center pb-10">
              {/* Submit Button */}
              <Button
                text="Add Question"
                Icon={FaPlusCircle}
                onBtnClick={addQuestion}
                width={200}
                outlined
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default IdentityAttributes;
