import React, { useState } from "react";
import { FaPlusCircle } from "react-icons/fa";
import { PrimaryLink } from "../../components/links";
import { InputField } from "../../components/forms";
import { useForm } from "react-hook-form";
import { FaSearch } from "react-icons/fa";
import { TableData } from "../../components/layouts/Table";
import { RiDeleteBin6Line } from "react-icons/ri";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { Link } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";
import { Spinner } from "../../components/generic";
import useFormHook from "../../hooks/useFormHook";
const FormListings = () => {
  const [active, setActive] = useState("All");
  const tabItems = ["All", "Unpublished", "Published"];
  const { control } = useForm();
  const { slug } = useAuthContext();
  const { forms, loading } = useFormHook();
  const tbHeader = [
    "Form Title",
    "No of questions",
    "no of sections",
    "last updated",
    "status",
    "actions",
  ];

  return (
    <div>
      {/* SPinner */}
      <Spinner loading={loading} />
      {/* Header */}
      <div className="w-full flex justify-between items-center">
        <h1 className="text-lg font-semibold">Forms</h1>
        <div className="flex items-center">
          {/* Search */}
          <div className="flex items-center">
            <InputField
              name="searchParams"
              placeholder="Search by Form Title"
              control={control}
              rules={{
                required: "Search Field can not be empty",
              }}
            />
            <button className="bg-primary h-10 w-10 flex justify-center items-center rounded-sm ml-2">
              <FaSearch className="text-white" />
            </button>
          </div>
          <PrimaryLink
            path={`/forms/add`}
            text={"New Form"}
            width={"w-[150px]"}
            Icon={FaPlusCircle}
          />
        </div>
      </div>
      {/* Tab and Search Container */}
      <div className="w-full flex items-center justify-between bg-white px-3 mb-5">
        {/* Tabs */}
        <ul className="flex items-center justify-start">
          {tabItems.map((item, index) => (
            <li
              className={`text-sm font-normal px-4 py-3 cursor-pointer ${
                item === active ? "bg-primary text-white" : ""
              }`}
              key={index}
              onClick={() => setActive(item)}
            >
              {item}
            </li>
          ))}
        </ul>
      </div>
      {/* Table Header */}
      <table className="w-full bg-white">
        <thead className="w-full bg-[#E0DFE5]">
          <tr className="w-full">
            {tbHeader.map((item, index) => (
              <th
                key={index}
                scope="col"
                className="px-6 py-3 uppercase text-left text-xs font-medium whitespace-nowrap"
              >
                {item}
              </th>
            ))}
          </tr>
        </thead>
        {/* Table content */}
        <tbody className="w-full bg-white">
          {forms.map((item, index) => (
            <tr
              className="bg-white border-b pt-8  hover:bg-gray-50"
              key={index}
            >
              <TableData item={item?.Title} />
              <TableData item={"10"} />
              <TableData item={"10"} />
              <TableData item={"2023-12-01"} />
              <TableData item={item.Status} />
              <div className="flex items-center justify-center py-3">
                <Link to={`/${slug}/forms/details/${item.id}`}>
                  <MdOutlineRemoveRedEye className="text-primary mx-4 text-lg" />
                </Link>
                <RiDeleteBin6Line className="text-red-600 text-lg" />
              </div>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default FormListings;
