import React from "react";
import { FaEdit, FaPlusCircle } from "react-icons/fa";
import { PrimaryLink } from "../../../components/links";
import { InputField } from "../../../components/forms";
import { useForm } from "react-hook-form";
import { FaSearch } from "react-icons/fa";
import { TableData } from "../../../components/layouts/Table";
import { RiDeleteBin6Line } from "react-icons/ri";
import { Link } from "react-router-dom";
import { useAuthContext } from "../../../context/AuthContext";
import { Spinner } from "../../../components/generic";
import useIdentityHook from "../../../hooks/useIdentityHook";
const MobileUserListings = () => {
  const { control } = useForm();
  const { slug } = useAuthContext();
  const { mobileUsers, loading } = useIdentityHook();
  const tbHeader = [
    "User",
    "Phone Number",
    "State",
    "Role",
    "status",
    "actions",
  ];
  return (
    <div>
      {/* SPinner */}
      <Spinner loading={loading} />
      {/* Header */}
      <div className="w-full flex justify-between items-center">
        <h1 className="text-lg font-semibold">Mobile Users</h1>
        <div className="flex items-center">
          {/* Search */}
          <div className="flex items-center">
            <InputField
              name="searchParams"
              placeholder="Search by Name or ID"
              control={control}
              rules={{
                required: "Search Field can not be empty",
              }}
            />
            <button className="bg-primary h-10 w-10 flex justify-center items-center rounded-sm ml-2">
              <FaSearch className="text-white" />
            </button>
          </div>
          <PrimaryLink
            path={`/mobile-users/add`}
            text={"New User"}
            width={"w-[140px]"}
            Icon={FaPlusCircle}
          />
        </div>
      </div>
      {/* Table Header */}
      <table className="w-full bg-white">
        <thead className="w-full bg-[#E0DFE5]">
          <tr className="w-full">
            {tbHeader.map((item, index) => (
              <th
                key={index}
                scope="col"
                className="px-6 py-3 uppercase text-left text-xs font-semibold whitespace-nowrap"
              >
                {item}
              </th>
            ))}
          </tr>
        </thead>
        {/* Table content */}
        <tbody className="w-full bg-white">
          {mobileUsers.map((item: any, index) => (
            <tr
              className="bg-white border-b pt-8  hover:bg-gray-50"
              key={index}
            >
              <td className="px-6 py-3">
                <p className="text-sm font-medium text-black">{`${item?.FirstName} ${item?.LastName}`}</p>
                <p className="text-xs text-zinc-500">{`${item?.Email}`}</p>
              </td>
              <TableData item={item?.Phone} />
              {/* <TableData item={item?.Gender} /> */}
              <TableData item={item?.State} />
              <TableData item={item?.Role} />
              <td className="">
                <div className="flex items-center px-6 py-3">
                  <div className="w-[6px] h-[6px] bg-primary rounded-full mr-2"></div>
                  <p className="text-xs">Active</p>
                </div>
              </td>
              <td>
                <div className="flex items-center px-6 py-3">
                  <Link to={`/${slug}/forms/details/${item.id}`}>
                    <FaEdit className="text-primary mx-4 text-lg" />
                  </Link>
                  <RiDeleteBin6Line className="text-red-600 text-lg" />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default MobileUserListings;
