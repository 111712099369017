import axios from "axios";
import { baseURL } from "../../URL";
import { FormFieldDTO, FormStructureDTO } from "../../typings/forms";

const onCreateFormStructure = async (postData: FormStructureDTO, config: any) => {
    try {
        const response = await axios.post(`${baseURL}/dforms`, postData, config);
        return response.data;
    } catch (error: any) {
        if (error?.response?.data?.message === undefined) {
            throw error.message
        } else {
            throw error?.response?.data?.message;
        }
    }
};
const onGetAllForms = async (config: any) => {
    try {
        const response = await axios.get(`${baseURL}/dforms`, config);
        return response.data;
    } catch (error: any) {
        if (error?.response?.data?.message === undefined) {
            throw error.message
        } else {
            throw error?.response?.data?.message;
        }
    }
};
const onGetFormDetails = async (id: any, config: any) => {
    try {
        const response = await axios.get(`${baseURL}/dforms/${id}`, config);
        return response.data;
    } catch (error: any) {
        if (error?.response?.data?.message === undefined) {
            throw error.message
        } else {
            throw error?.response?.data?.message;
        }
    }
};
const onCreateFormField = async (postData: FormFieldDTO, config: any) => {
    try {
        const response = await axios.post(`${baseURL}/dfields`, postData, config);
        return response.data;
    } catch (error: any) {
        if (error?.response?.data?.message === undefined) {
            throw error.message
        } else {
            throw error?.response?.data?.message;
        }
    }
};
const onCreateFormPermission = async (postData: {
    UserUniqueID: string,
    FormID: string,
}, config: any) => {
    try {
        const response = await axios.post(`${baseURL}/mobileformpermission`, postData, config);
        return response.data;
    } catch (error: any) {
        if (error?.response?.data?.message === undefined) {
            throw error.message
        } else {
            throw error?.response?.data?.message;
        }
    }
};
const OnUpdateFormStatus = async (postData: {
    AuthorID: string,
    Status: string,
}, FormID: string, config: any) => {
    try {
        const response = await axios.patch(`${baseURL}/dforms/${FormID}`, postData, config);
        return response.data;
    } catch (error: any) {
        if (error?.response?.data?.message === undefined) {
            throw error.message
        } else {
            throw error?.response?.data?.message;
        }
    }
};
export { onCreateFormStructure, onGetAllForms, onGetFormDetails, onCreateFormField, onCreateFormPermission, OnUpdateFormStatus }