import { useState, useEffect } from "react";
import { useAuthContext } from "../context/AuthContext";
import {
  onGetAllIdentities,
  onGetMobileUsers,
} from "../utils/services/api/identities";
import { toast } from "react-toastify";

const useIdentityHook = () => {
  const [mobileUsers, setMobileUsers] = useState([]);
  const [identities, setIdentities] = useState([]);
  const [loading, setLoading] = useState(false);

  const { config, authUser } = useAuthContext();

  const getUsers = async () => {
    try {
      let tempUsers = await onGetMobileUsers(config);
      setMobileUsers(tempUsers.data);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      toast.error(error);
    }
  };
  const GetAllIdentities = async () => {
    if (authUser?.OrganizationID) {
      try {
        let temp = await onGetAllIdentities(
          parseInt(authUser?.OrganizationID),
          config
        );
        setIdentities(temp.data);
        setLoading(false);
      } catch (error: any) {
        setLoading(false);
        toast.error(error);
      }
    }
  };

  useEffect(() => {
    getUsers();
    GetAllIdentities();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    getUsers();
    GetAllIdentities();
    // eslint-disable-next-line
  }, [authUser]);
  return { mobileUsers, identities, loading };
};

export default useIdentityHook;
