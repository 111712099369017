import React, { useState } from "react";

interface Question {
  id: number;
  text: string;
  type: "Text" | "Multiple" | "Radio" | "Select";
  required: boolean;
  maxLength: number | null;
  sectionID: number;
  options?: string[];
}

interface Section {
  id: number;
  name: string;
}

const DynamicForm: React.FC = () => {
  const [questions, setQuestions] = useState<Question[]>([]);
  const [sections, setSections] = useState<Section[]>([
    { id: 1, name: "Biodata" },
  ]);
  const [activeSection, setActiveSection] = useState<number>(1);
  const [editingSectionTitle, setEditingSectionTitle] = useState<string>("");
  const [deletingSection, setDeletingSection] = useState<number | null>(null);
  const addSection = () => {
    const newSection: Section = {
      id: sections.length + 1,
      name: `Section ${sections.length + 1}`,
    };
    setSections([...sections, newSection]);
  };
  const addQuestion = () => {
    const newQuestion: Question = {
      id: questions.length + 1,
      text: "",
      type: "Text",
      required: false,
      maxLength: null,
      sectionID: activeSection,
    };
    setQuestions([...questions, newQuestion]);
  };

  const deleteQuestion = (questionId: number) => {
    setQuestions((prevQuestions) =>
      prevQuestions.filter((q) => q.id !== questionId)
    );
  };

  const addOption = (questionId: number) => {
    setQuestions((prevQuestions) =>
      prevQuestions.map((q) =>
        q.id === questionId && ["Multiple", "Radio", "Select"].includes(q.type)
          ? { ...q, options: q.options ? [...q.options, ""] : [""] }
          : q
      )
    );
  };

  const deleteOption = (questionId: number, optionIndex: number) => {
    setQuestions((prevQuestions) =>
      prevQuestions.map((q) =>
        q.id === questionId && q.options
          ? { ...q, options: q.options.filter((_, i) => i !== optionIndex) }
          : q
      )
    );
  };

  const deleteSection = () => {
    if (deletingSection !== null) {
      setSections((prevSections) =>
        prevSections.filter((s) => s.id !== deletingSection)
      );
      setQuestions((prevQuestions) =>
        prevQuestions.filter((q) => q.sectionID !== deletingSection)
      );
      setDeletingSection(null);
    }
  };

  const toggleRequired = (questionId: number) => {
    setQuestions((prevQuestions) =>
      prevQuestions.map((q) =>
        q.id === questionId ? { ...q, required: !q.required } : q
      )
    );
  };

  const setMaxLength = (questionId: number, maxLength: number | null) => {
    setQuestions((prevQuestions) =>
      prevQuestions.map((q) => (q.id === questionId ? { ...q, maxLength } : q))
    );
  };

  const changeSection = (sectionId: number) => {
    setActiveSection(sectionId);
    setEditingSectionTitle("");
  };

  const updateQuestionType = (
    questionId: number,
    newType: "Text" | "Multiple" | "Radio" | "Select"
  ) => {
    setQuestions((prevQuestions) =>
      prevQuestions.map((q) =>
        q.id === questionId ? { ...q, type: newType } : q
      )
    );
  };

  const updateSectionTitle = () => {
    setSections((prevSections) =>
      prevSections.map((section) =>
        section.id === activeSection
          ? { ...section, name: editingSectionTitle }
          : section
      )
    );
  };

  return (
    <div className="flex">
      {/* Sections Container */}
      <div className="w-1/4 p-4">
        <h2 className="text-lg font-bold mb-4">Sections</h2>
        {sections.map((section) => (
          <div
            key={section.id}
            className={`cursor-pointer py-2 ${
              activeSection === section.id ? "bg-blue-500 text-white" : ""
            }`}
            onClick={() => changeSection(section.id)}
          >
            Section {section.id} [{section.name}]
          </div>
        ))}
        <div className="mt-4">
          <label>Edit Section Title:</label>
          <input
            type="text"
            value={editingSectionTitle}
            onChange={(e) => setEditingSectionTitle(e.target.value)}
          />
          <button onClick={updateSectionTitle}>Update</button>
        </div>
        <button
          onClick={() => setDeletingSection(activeSection)}
          className="mt-4"
        >
          Delete Section
        </button>
        <button onClick={addSection} className="mt-4">
          Add Section
        </button>
        {deletingSection !== null && (
          <div className="mt-2 text-red-500">
            Please confirm deletion below.
          </div>
        )}
      </div>

      {/* Questions Container */}
      <div className="w-3/4 p-4">
        <h2 className="text-lg font-bold mb-4">
          Questions for {sections.find((s) => s.id === activeSection)?.name}
        </h2>
        <div className="mb-4">
          <label>Active Section Title:</label>
          <input
            type="text"
            value={sections.find((s) => s.id === activeSection)?.name || ""}
            readOnly
          />
        </div>
        <button className="mb-4" onClick={addQuestion}>
          Add Question
        </button>
        {questions
          .filter((q) => q.sectionID === activeSection)
          .map((question) => (
            <div key={question.id} className="mb-4">
              <label>{`Question ${question.id}`}</label>
              <input
                type="text"
                value={question.text}
                onChange={(e) =>
                  setQuestions((prevQuestions) =>
                    prevQuestions.map((q) =>
                      q.id === question.id ? { ...q, text: e.target.value } : q
                    )
                  )
                }
              />
              <select
                value={question.type}
                onChange={(e) =>
                  updateQuestionType(
                    question.id,
                    e.target.value as "Text" | "Multiple" | "Radio" | "Select"
                  )
                }
              >
                <option value="Text">Text</option>
                <option value="Multiple">Multiple</option>
                <option value="Radio">Radio</option>
                <option value="Select">Select</option>
              </select>
              {["Multiple", "Radio", "Select"].includes(question.type) && (
                <div>
                  <label>Options:</label>
                  {question.options &&
                    question.options.map((option, index) => (
                      <div key={index} className="flex items-center mb-2">
                        <input
                          type="text"
                          value={option}
                          onChange={(e) =>
                            setQuestions((prevQuestions) =>
                              prevQuestions.map((q) =>
                                q.id === question.id && q.options
                                  ? {
                                      ...q,
                                      options: q.options.map((o, i) =>
                                        i === index ? e.target.value : o
                                      ),
                                    }
                                  : q
                              )
                            )
                          }
                        />
                        <button
                          onClick={() => deleteOption(question.id, index)}
                          className="ml-2 p-1 bg-red-500 text-white"
                        >
                          Delete
                        </button>
                      </div>
                    ))}
                  <button
                    onClick={() => addOption(question.id)}
                    className="p-1 bg-green-500 text-white"
                  >
                    Add Option
                  </button>
                </div>
              )}
              <div>
                <label>Required:</label>
                <input
                  type="checkbox"
                  checked={question.required}
                  onChange={() => toggleRequired(question.id)}
                />
              </div>
              <div>
                <label>Max Length:</label>
                <input
                  type="number"
                  value={question.maxLength || ""}
                  onChange={(e) =>
                    setMaxLength(question.id, e.target.valueAsNumber || null)
                  }
                />
              </div>
              <button
                onClick={() => deleteQuestion(question.id)}
                className="mt-2 p-1 bg-red-500 text-white"
              >
                Delete Question
              </button>
            </div>
          ))}
      </div>
      {deletingSection !== null && (
        <div className="fixed inset-0 bg-gray-700 bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-4 rounded shadow-md">
            <p className="text-lg font-semibold">
              Are you sure you want to delete this section?
            </p>
            <div className="flex justify-end mt-4">
              <button onClick={() => setDeletingSection(null)} className="mr-2">
                Cancel
              </button>
              <button
                onClick={deleteSection}
                className="p-1 bg-red-500 text-white"
              >
                Confirm Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DynamicForm;
