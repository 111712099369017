const FormFieldData = [
    { value: "Text", label: "Text", },
    { value: "Number", label: "Number", },
    { value: "Radio", label: "Radio", },
    { value: "UID", label: "UID", },
    { value: "Drop Down", label: "Drop Down", },
    { value: "Multiple Choice", label: "Multiple Choice", },
    { value: "Picture", label: "Picture", },
    { value: "Video", label: "Video", },
    { value: "Date", label: "Date", },
    { value: "Time", label: "Time", },
    { value: "Signature", label: "Signature", },
    { value: "Number", label: "Number", },
    { value: "Location", label: "Location", },
    { value: "GeoMapping", label: "GeoMapping", },
];

export { FormFieldData }