import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Dashboard } from "../pages";
import ProtectedRoute from "./ProtectedRoutes";
import { BaseContainer } from "../components/layouts";
import { Login } from "../pages/auth";
import {
  CreateFormFields,
  CreateFormStructure,
  FormDetails,
  FormListings,
  FormRecords,
  PublishForm,
} from "../pages/form-management";
import DynamicForm from "../pages/form-management/DynamicForm";
import { AddMobileUser, MobileUserListings } from "../pages/users/mobile";
import {
  AudienceListings,
  IdentityAttributes,
  IdentityStructure,
} from "../pages/users/target-audience";

const AdminRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<ProtectedRoute />}>
          <Route path="/" element={<BaseContainer />}>
            {/* <Route index element={<Home />} /> */}
            <Route index path="/:slug" element={<Dashboard />} />
            <Route path="/:slug/forms" element={<FormListings />} />
            <Route path="/:slug/forms/add" element={<CreateFormStructure />} />
            <Route
              path="/:slug/forms/add-fields/:id"
              element={<CreateFormFields />}
            />
            <Route path="/:slug/forms/records" element={<FormRecords />} />
            <Route path="/:slug/forms/details/:id" element={<FormDetails />} />
            <Route path="/:slug/form/publish/:id" element={<PublishForm />} />

            {/* MObile User */}
            <Route
              path="/:slug/mobile-users"
              element={<MobileUserListings />}
            />
            <Route path="/:slug/mobile-users/add" element={<AddMobileUser />} />
            {/* Target Audience */}
            <Route
              path="/:slug/target-audience"
              element={<AudienceListings />}
            />
            <Route path="/:slug/audience/add" element={<IdentityStructure />} />
            <Route
              path="/:slug/audience/add-fields/:id"
              element={<IdentityAttributes />}
            />
          </Route>
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/dyna" element={<DynamicForm />} />
      </Routes>
    </Router>
  );
};

export default AdminRoutes;
