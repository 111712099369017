import React from "react";
import { BackLink, PrimaryLink } from "../../components/links";
import { Button, InputField } from "../../components/forms";
import { useForm } from "react-hook-form";
import { FaSearch } from "react-icons/fa";

const FormRecords = () => {
  const { control } = useForm();

  const tbHeader = [
    "header item",
    "header item",
    "header item",
    "header item",
    "header item",
    "header item",
  ];
  return (
    <div>
      {/* Header */}
      <div className="w-full flex justify-between items-center">
        {/* Header */}
        <BackLink title="RA Adoption" />
        {/* Header Button */}
        <div className="flex items-center">
          <PrimaryLink
            outlined
            text="View Form"
            width={"200"}
            path="/forms/details"
          />
          <Button width={200} text="Export CSV" />
        </div>
      </div>
      {/* Tab and Search Container */}
      <div className="w-full flex items-center justify-between bg-white px-5">
        {/* Counts */}
        <h1 className="text-black text-lg font-medium">Records(23)</h1>
        {/* Search */}
        <div className="flex items-center">
          <InputField
            name="searchParams"
            placeholder="Search Records"
            control={control}
            rules={{
              required: "Search Field can not be empty",
            }}
          />
          <button className="bg-primary h-10 w-10 flex justify-center items-center rounded-sm ml-2">
            <FaSearch className="text-white" />
          </button>
        </div>
      </div>
      {/* Table Header */}
      <table className="w-full">
        <thead className="w-full">
          <tr className="w-full">
            {tbHeader.map((item, index) => (
              <th
                key={index}
                scope="col"
                className="px-2 py-3 uppercase text-xs whitespace-nowrap"
              >
                {item}
              </th>
            ))}
          </tr>
        </thead>
        {/* Table content */}
        <tbody className="w-full bg-white"></tbody>
      </table>
      <div className="h-[300px] w-full bg-white"></div>
    </div>
  );
};

export default FormRecords;
