import axios from "axios";
import { baseURL } from "../../URL";
import { LoginDto } from "../../typings/auth";

const onLogin = async (postData: LoginDto) => {
    try {
        const response = await axios.post(`${baseURL}/identity/login`, postData);
        return response.data;
    } catch (error: any) {
        if (error?.response?.data?.message === undefined) {
            throw error.message
        } else {
            throw error?.response?.data?.message;
        }
    }
};

export { onLogin }