import { useState, useEffect } from "react";
import { onGetAllForms } from "../utils/services/api/dynamicFom";
import { useAuthContext } from "../context/AuthContext";
import { EndpointFormDTO } from "../utils/typings/forms";
import { toast } from "react-toastify";

const useFormHook = () => {
  const [forms, setForms] = useState<EndpointFormDTO[]>([]);
  const [loading, setLoading] = useState(false);
  const { config, slug } = useAuthContext();
  const getForms = async () => {
    setLoading(true);
    try {
      setLoading(true);
      let res = await onGetAllForms(config);
      setLoading(false);
      setForms(res?.data);
    } catch (error: any) {
      setLoading(false);
      toast.error(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getForms();
  }, []);

  const refresh = () => {
    getForms();
  };

  return { forms, loading, refresh };
};

export default useFormHook;
