import React, { useEffect, useState } from "react";
import { FaRegEdit } from "react-icons/fa";
import { BackLink, PrimaryLink } from "../../components/links";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useParams } from "react-router-dom";
import { onGetFormDetails } from "../../utils/services/api/dynamicFom";
import { EndpointFormDTO } from "../../utils/typings/forms";
import { useAuthContext } from "../../context/AuthContext";
import { toast } from "react-toastify";
import { Spinner } from "../../components/generic";
import { LuArrowUpToLine } from "react-icons/lu";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";

const FormDetails = () => {
  const [form, setForm] = useState<EndpointFormDTO>();
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const { config, slug } = useAuthContext();

  const getForm = async () => {
    setLoading(true);
    try {
      setLoading(true);
      let res = await onGetFormDetails(id, config);
      setLoading(false);
      setForm(res?.data);
    } catch (error: any) {
      setLoading(false);
      toast.error(error);
    }
    setLoading(false);
  };
  useEffect(() => {
    getForm();
    // eslint-disable-next-line
  }, []);
  return (
    <div>
      <Spinner loading={loading} />
      {/* Header */}
      <div className="w-full flex justify-between items-center">
        {/* Header */}
        <BackLink title={form?.Title} />
        {/* Header Button */}
        <div className="flex items-center">
          <Link
            to={`/${slug}/forms/add-fields/${id}`}
            className="flex items-center cursor-pointer"
          >
            <FaRegEdit className="text-primary text-lg" />
            <p className="text-xs text-primary ml-1 font-medium">edit</p>
          </Link>
          <div className="flex items-center mx-3 cursor-pointer">
            <RiDeleteBin6Line className="text-red-500 text-lg" />
            <p className="text-xs text-red-500 ml-1 font-medium">Delete</p>
          </div>
          {form?.Status !== "published" && (
            <PrimaryLink
              path={`/form/publish/${id}`}
              text={"Publish Form"}
              width={"w-[150px]"}
              Icon={LuArrowUpToLine}
            />
          )}
        </div>
      </div>
      {/* Content */}
      <div className="bg-white drop-shadow-md rounded-md my-5">
        {/* Form Information */}
        <h1 className="font-medium text-zinc-500 text-md p-4">
          Form Information
        </h1>
        {/* Form Header */}
        <div className="bg-green-50 py-6 px-4">
          {/* Image */}
          {/* Form Title */}
          <div className="border-b-2 border-b-primary px-4 py-3">
            <p className="text-xs text-black">Title</p>
            <h1 className="text-zinc-600 text-xl">{form?.Title}</h1>
          </div>
          {/* Form Description */}
          <div className="px-4 py-5">
            {/* Desc */}
            <div className="">
              <p className="text-black text-sm font-medium">Description</p>
              <h1 className="text-xs text-gray-600 ">{form?.Description}</h1>
            </div>
            {/* Instruction */}
            <div className="mt-2">
              <p className="text-black text-sm font-medium">Instructions</p>
              <h1 className="text-xs text-gray-600">{form?.Instruction}</h1>
            </div>
          </div>
        </div>
        {/* Form Content */}
        {form?.Fields?.length > 0 && <FormContent fields={form?.Fields} />}
      </div>
    </div>
  );
};

export default FormDetails;

const FormContent = ({ fields }: any) => {
  const groupedFields = fields.reduce(
    (result: Record<string, any>, field: any) => {
      const sectionId = field.Section;
      const sectionKey = `section_${sectionId}`;

      if (!result[sectionKey]) {
        result[sectionKey] = {
          sectionId: sectionId,
          fields: [],
        };
      }
      result[sectionKey].fields.push(field);
      return result;
    },
    {}
  );

  return (
    <div className="container">
      <h1 className=" text-lg font-medium text-zinc-600 px-4 py-2">Sections</h1>
      {Object.values(groupedFields).map((section: any) => (
        <div key={section.sectionId} className="mb-8">
          <div className="bg-green-50 py-3 px-4 flex items-center mb-5">
            <h1 className="bg-white text-lg py-1 px-3 text-primary text-center font-medium border border-gray-300 rounded-sm drop-shadow-sm">{`Section ${section.sectionId}`}</h1>
            <h2 className="text-xl font-medium text-zinc-600 ml-4">{`${section.fields[0].SectionTitle}`}</h2>
          </div>
          <div className="">
            {section.fields.map((field: any, index: any) => (
              <div
                key={index}
                className="my-2 py-2 px-4 border-b border-b-gray-200 "
              >
                <p className="text-sm mb-2 font-semibold">{`Question ${
                  index + 1
                }`}</p>
                <p className="text-xs font-normal text-zinc-700">
                  Display Text
                </p>
                <p className="text-zinc-600 text-md">{field.Label}</p>
                <p className="text-sm my-2 font-medium text-zinc-700">
                  {field?.Type}
                </p>
                {field?.Options?.length > 0 && (
                  <div className="ml-4 my-2">
                    {/* <p className="text-xs font-medium text-zinc-700">
                      {field?.Type} Options
                    </p> */}
                    {JSON.parse(field?.Options).map(
                      (item: string, index: number) => (
                        <div className="flex items-center" key={index}>
                          <Icon
                            icon={"mdi:checkbox-blank-outline"}
                            color="gray"
                            height={15}
                            width={15}
                          />
                          <span className="w-full px-3 py-1 mr-2 text-xs">
                            {item}
                          </span>
                        </div>
                      )
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};
