import React, { useEffect, useState } from "react";
import { BackLink } from "../../components/links";
import { Button } from "../../components/forms";
import { FormFieldData } from "../../utils/predefinedData";
import { FaPlusCircle, FaSave } from "react-icons/fa";
import { FaCirclePlus } from "react-icons/fa6";
import { EndpointFormDTO } from "../../utils/typings/forms";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";
import {
  onCreateFormField,
  onGetFormDetails,
} from "../../utils/services/api/dynamicFom";
import { toast } from "react-toastify";
import { Spinner } from "../../components/generic";
import { Icon } from "@iconify/react";
import { generateUniqueID } from "../../utils/functions/generic";

interface Question {
  id: any;
  text: string;
  type: any;
  required: boolean;
  maxLength: number | null;
  sectionID: number;
  options?: string[];
}

interface Section {
  id: number;
  name: string;
}

const CreateFormFields = () => {
  const uniqueID = generateUniqueID();
  const [form, setForm] = useState<EndpointFormDTO>();
  const [loading, setLoading] = useState(false);
  const [questions, setQuestions] = useState<Question[]>([
    {
      id: uniqueID,
      text: "",
      type: "Text",
      required: false,
      maxLength: null,
      sectionID: 1,
    },
  ]);
  const [sections, setSections] = useState<Section[]>([
    { id: 1, name: "Section Title" },
  ]);
  const [activeSection, setActiveSection] = useState<number>(1);
  const [deletingSection, setDeletingSection] = useState<number | null>(null);
  const [currentOption, setCurrentOption] = useState("");
  const [editingOptionIndex, setEditingOptionIndex] = useState<number | null>(
    null
  );

  const { id } = useParams();
  const { config, slug } = useAuthContext();
  const navigate = useNavigate();

  const delay = (ms: any) => new Promise((resolve) => setTimeout(resolve, ms));

  const getForm = async () => {
    setLoading(true);
    try {
      setLoading(true);
      let res = await onGetFormDetails(id, config);
      setLoading(false);
      setForm(res?.data);
    } catch (error: any) {
      setLoading(false);
      toast.error(error);
    }
    setLoading(false);
  };
  useEffect(() => {
    getForm();
    // eslint-disable-next-line
  }, []);
  const addSection = () => {
    const newSection: Section = {
      id: sections.length + 1,
      name: `Section Title`,
    };
    setSections([...sections, newSection]);
  };
  const findSectionTitle = (ParamsID: any) => {
    const section = sections.find((section) => section.id === ParamsID);
    const title = section ? section.name : "Section not found";
    return title;
  };
  const addQuestion = () => {
    const newQuestion: Question = {
      id: uniqueID,
      text: "",
      type: "Text",
      required: false,
      maxLength: null,
      sectionID: activeSection,
    };
    setQuestions([...questions, newQuestion]);
  };

  const deleteQuestion = (questionId: any) => {
    setQuestions((prevQuestions) =>
      prevQuestions.filter((q) => q.id !== questionId)
    );
  };
  const addOption = (questionId: any) => {
    if (currentOption.trim() !== "") {
      setQuestions((prevQuestions) =>
        prevQuestions.map((q) =>
          q.id === questionId &&
          ["Drop Down", "Single Select", "Multiple Choice", "Radio"].includes(
            q.type
          )
            ? {
                ...q,
                options: q.options
                  ? [...q.options, currentOption.trim()]
                  : [currentOption.trim()],
              }
            : q
        )
      );
      setCurrentOption(""); // Clear the input field after adding an option
    }
  };
  const deleteOption = (questionId: any, optionIndex: number) => {
    setQuestions((prevQuestions) =>
      prevQuestions.map((q) =>
        q.id === questionId && q.options
          ? { ...q, options: q.options.filter((_, i) => i !== optionIndex) }
          : q
      )
    );
  };

  const deleteSection = () => {
    if (deletingSection !== null) {
      setSections((prevSections) =>
        prevSections.filter((s) => s.id !== deletingSection)
      );
      setQuestions((prevQuestions) =>
        prevQuestions.filter((q) => q.sectionID !== deletingSection)
      );
      setDeletingSection(null);
    }
  };

  const toggleRequired = (questionId: any) => {
    setQuestions((prevQuestions) =>
      prevQuestions.map((q) =>
        q.id === questionId ? { ...q, required: !q.required } : q
      )
    );
  };

  const changeSection = (sectionId: number) => {
    setActiveSection(sectionId);
  };

  const updateQuestionType = (questionId: any, newType: any) => {
    setQuestions((prevQuestions) =>
      prevQuestions.map((q) =>
        q.id === questionId ? { ...q, type: newType } : q
      )
    );
  };
  const onSubmit = async () => {
    // Validate options for specific question types
    const invalidOptions = questions.filter(
      (q) =>
        ["Drop Down", "Single Select", "Multiple Choice", "Radio"].includes(
          q.type
        ) &&
        (!q.options ||
          q.options.length < 2 ||
          q.options.some((opt) => opt === ""))
    );

    // Validate at least one question per section
    const sectionsWithoutQuestions = sections.filter(
      (s) => !questions.some((q) => q.sectionID === s.id)
    );

    // Validate questions are not empty
    if (questions.some((q) => q.text.trim() === "")) {
      toast.error("All questions must have a title.");
    } else if (invalidOptions.length > 0) {
      toast.error(
        "Questions with types Drop Down, Single Select, Multiple Choice, or Radio must have at least two options, and options cannot be empty."
      );
    } else if (sectionsWithoutQuestions.length > 0) {
      toast.error("Each section must have at least one question.");
    } else if (
      sections.some((s) => s.name.trim() === "" || s.name === "Section Title")
    ) {
      // Validate section titles are not empty and not the default value
      toast.error("All Sections Must have a title");
    } else {
      try {
        setLoading(true);
        for (const question of questions) {
          const fieldData = {
            FormID: id?.toString(),
            Label: question.text,
            Type: question.type,
            Section: question.sectionID.toString(),
            Options: question.options ? JSON.stringify(question.options) : "",
            Validations: JSON.stringify([{ required: question.required }]),
            SectionTitle: findSectionTitle(question.sectionID),
          };
          // Make API call for the current question
          await onCreateFormField(fieldData, config);
          // Add a delay if needed (to simulate sequential submission)
          await delay(1000); // Adjust the delay duration as needed
        }

        setLoading(false);
        toast.success("All questions added successfully");
        navigate(`/${slug}/forms`);
      } catch (error: any) {
        setLoading(false);
        toast.error(error);
      }
      setLoading(false);
    }
  };
  return (
    <div className=" pb-16">
      <Spinner loading={loading} />
      {/* Header */}
      <div className="flex justify-between items-center">
        <BackLink title={form?.Title} />
        <Button onBtnClick={onSubmit} text="Save" Icon={FaSave} width={120} />
      </div>
      {/* Content */}
      <div className="h-[100vh] flex justify-between">
        {/* Section Container */}
        <div className="w-[30%]  bg-white pt-4 px-3 rounded-sm">
          <div className="flex justify-between items-center mb-4 border-b border-b-gray-400">
            <h2 className="text-md font-medium">Sections</h2>
            <Button
              Icon={FaCirclePlus}
              text="Add Section"
              onBtnClick={addSection}
              outlined
              width={120}
            />
          </div>
          {sections.map((section) => (
            <div
              key={section.id}
              className={`cursor-pointer text-xs p-3 rounded-sm ${
                activeSection === section.id ? "bg-primary text-white" : ""
              }`}
              onClick={() => changeSection(section.id)}
            >
              Section {section.id}:{"   "}
              <span className="font-bold">[{section.name}]</span>
            </div>
          ))}
        </div>
        {/* Main Content */}
        <div className="w-[65%] h-[500px] bg-white px-4 pt-4 rounded-sm overflow-y-scroll pb-10">
          {/* Section Header */}
          <div className="px-4 py-2 bg-green-50 ">
            <p className="bg-white text-xs py-1 px-3 text-primary text-center font-bold w-[90px] border border-gray-300 rounded-sm drop-shadow-sm">{`Section ${activeSection}`}</p>
            <div className="flex items-center justify-between mt-2">
              {/* Input */}
              <div className="w-[91%]">
                <input
                  type="text"
                  value={
                    sections.find((s) => s.id === activeSection)?.name || ""
                  }
                  onChange={(e) => {
                    setSections((prevSections) =>
                      prevSections.map((section) =>
                        section.id === activeSection
                          ? { ...section, name: e.target.value }
                          : section
                      )
                    );
                  }}
                  className={`block bg-gray-50 text-xs py-3 px-4 rounded-sm w-full shadow-xs border`}
                />
              </div>
              {/* Delete */}
              <button
                onClick={() => setDeletingSection(activeSection)}
                disabled={activeSection !== 1 ? false : true}
                className="h-full w-[8%] bg-white flex items-center justify-center py-3 border border-gray-300 rounded-sm drop-shadow-sm"
              >
                <Icon icon={"material-symbols:delete-outline"} color="red" />
              </button>
            </div>
          </div>
          {/* Questio Section */}
          <div className="w-full bg-white pt-6 px-2 ">
            {/* Question and Question Type */}

            {questions
              .filter((q) => q.sectionID === activeSection)
              .map((question, questionIndex) => (
                <div
                  key={question.id}
                  className="mb-6 bg-white border border-gray-200 drop-shadow-sm py-3"
                >
                  {/* Question and Input Type */}
                  <div className="w-full flex justify-between items-center my-2 px-3 mb-4">
                    {/* Question Title */}
                    <div className="w-[65%]">
                      <p className="text-xs mb-2">{`Question ${
                        questionIndex + 1
                      }`}</p>
                      <input
                        type="text"
                        placeholder="Enter Question"
                        value={question.text}
                        onChange={(e) =>
                          setQuestions((prevQuestions) =>
                            prevQuestions.map((q) =>
                              q.id === question.id
                                ? { ...q, text: e.target.value }
                                : q
                            )
                          )
                        }
                        className={`block bg-gray-50 text-xs py-3 px-4 rounded-sm w-full shadow-xs border`}
                      />
                    </div>
                    {/* Input Type */}
                    <div className="w-[30%]">
                      <div className="flex items-center justify-between">
                        <p className="text-xs mb-2">{"Input Type"}</p>
                      </div>
                      <select
                        value={question.type}
                        onChange={(e) =>
                          updateQuestionType(question.id, e.target.value as any)
                        }
                        className={`block bg-gray-50 text-xs py-3 px-4 rounded-sm w-full shadow-xs border "border-gray-200"`}
                      >
                        {FormFieldData.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  {/* Option Sections */}
                  {question.options &&
                    question.options.map((option, index) => (
                      <div
                        key={index}
                        className="w-full flex items-center justify-between mb-2 px-6"
                      >
                        <div className="w-[80%] flex items-center">
                          {editingOptionIndex === index ? (
                            <input
                              type="text"
                              value={currentOption}
                              className="w-full border rounded px-3 py-3 mr-2 text-xs"
                              placeholder="Enter Option"
                              onChange={(e) => setCurrentOption(e.target.value)}
                            />
                          ) : (
                            <div className="flex items-center">
                              <Icon
                                icon={"mdi:checkbox-blank-outline"}
                                color="gray"
                                height={40}
                                width={40}
                              />
                              <span className="w-full px-3 py-2 mr-2 text-xs">
                                {option}
                              </span>
                            </div>
                          )}
                        </div>
                        <div className="w-[20%] flex items-center justify-end">
                          <button
                            onClick={() => {
                              if (editingOptionIndex === index) {
                                // Save the edited option
                                setQuestions((prevQuestions) =>
                                  prevQuestions.map((q) =>
                                    q.id === question.id && q.options
                                      ? {
                                          ...q,
                                          options: q.options.map((o, i) =>
                                            i === index
                                              ? currentOption.trim()
                                              : o
                                          ),
                                        }
                                      : q
                                  )
                                );
                                setEditingOptionIndex(null);
                                setCurrentOption("");
                              } else {
                                // Start editing the option
                                setCurrentOption(option);
                                setEditingOptionIndex(index);
                              }
                            }}
                            className="ml-2 p-1"
                          >
                            {editingOptionIndex === index ? (
                              <Icon
                                icon={"material-symbols:save-outline"}
                                className="text-primary"
                                height={20}
                                width={20}
                              />
                            ) : (
                              <Icon
                                height={20}
                                width={20}
                                icon={"tabler:edit"}
                                className="text-primary"
                              />
                            )}
                          </button>
                          <button
                            onClick={() => {
                              addOption(question.id);
                              setCurrentOption(option);
                            }}
                            className="ml-2 p-1"
                          >
                            <Icon
                              icon={"uil:copy"}
                              height={20}
                              width={20}
                              className="text-primary"
                            />
                          </button>
                          <button
                            onClick={() => deleteOption(question.id, index)}
                            className="ml-2 p-1"
                          >
                            <Icon
                              icon={"uil:times"}
                              height={30}
                              width={30}
                              className="text-primary"
                            />
                          </button>
                        </div>
                      </div>
                    ))}
                  {[
                    "Drop Down",
                    "Single Select",
                    "Multiple Choice",
                    "Radio",
                  ].includes(question.type) && (
                    <div className="w-full px-3">
                      <div className="w-full flex justify-between items-center">
                        <input
                          type="text"
                          value={currentOption}
                          onChange={(e) => setCurrentOption(e.target.value)}
                          className="w-full border rounded px-3 py-4 mr-2 text-xs"
                          placeholder="Enter Option"
                        />
                        <Button
                          text="Add Option"
                          onBtnClick={() => addOption(question.id)}
                          width={180}
                          outlined
                          Icon={FaCirclePlus}
                        />
                      </div>
                    </div>
                  )}
                  {/* Footer */}
                  <div className="flex items-center justify-between mt-4 border-t border-t-gray-300 pt-3 px-3">
                    {/* Validation */}
                    <div>
                      <input
                        type="checkbox"
                        checked={question.required}
                        onChange={() => toggleRequired(question.id)}
                        className="h-[16px] w-[16px] cursor-pointer"
                      />
                      <label className="text-xs ml-2">Required</label>
                    </div>
                    {/* Delete */}
                    <button
                      onClick={() => deleteQuestion(question.id)}
                      disabled={questions.length <= 1 ? true : false}
                      className=""
                    >
                      <Icon
                        icon={"material-symbols:delete-outline"}
                        color="red"
                        height={20}
                        width={20}
                      />
                    </button>
                  </div>
                </div>
              ))}
            {/* Validation and Add Button */}
            <div className="flex justify-end items-center pb-10">
              {/* Submit Button */}
              <Button
                text="Add Question"
                Icon={FaPlusCircle}
                onBtnClick={addQuestion}
                width={200}
                outlined
              />
            </div>
          </div>
        </div>
      </div>
      {deletingSection !== null && (
        <div className="fixed inset-0 bg-gray-700 bg-opacity-50 flex items-center justify-center">
          <div className="ml-0 p-4 w-[30%] bg-white rounded-sm py-8">
            <div className="w-full flex justify-center items-center">
              <div className="flex justify-center items-center border-4 border-red-600 rounded-full h-[80px] w-[80px]">
                <Icon
                  icon={"icon-park-outline:delete"}
                  height={50}
                  width={50}
                  className="text-red-600"
                />
              </div>
            </div>
            <h1 className="text-lg font-medium text-center my-5">
              Are you sure you want to delete this section?
            </h1>
            <div className="flex justify-center items-center md:space-x-2">
              <Button
                text="Cancel"
                outlined
                width={100}
                onBtnClick={() => setDeletingSection(null)}
              />
              <Button
                text="Save Changes"
                width={130}
                onBtnClick={deleteSection}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default CreateFormFields;
